import React from 'react'

function Promociones() {
    return (
        <>
        <section className="my-favoritos back-trendy-1">
            <h1 className="text-center title-my-favoritos">Ofertas y Promociones</h1>
         
        </section>
        
       

        
        <section className="section-top-ten">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                
                <iframe className="iframe-mega" title="promociones" src="https://q.megatravel.com.mx/tools/ofertas-viaje.php?&txtColor=222436&aColor=222436&ahColor=ffe500&thBG=222436&thTxColor=fff&ff=2" width="100%" height="1200" border="0"align="left" allowtransparency="true" frameborder="0"> </iframe>
                </div> 
            </div> 
        </div>   
        </section>  
        
        </>
    )
}

export default Promociones
